.accordion__item {
  margin-top: 30px;
}
.accordion__item__title {
  display: block;
  padding: 15px;
  cursor: pointer;
  background: #eee;
  color: #333;
  position: relative;
}
.accordion__item__title:after {
  content: "+";
  position: absolute;
  right: 20px;
  top: 50%;
  font-size: 2rem;
  line-height: 2rem;
  margin-top: -1rem;
  font-weight: bold;
}
.accordion__item:nth-of-type(2n) .accordion__item__title {
  background: #ddd;
}
.accordion__item__content {
  display: none;
  padding: 20px;
}
.accordion__item__content:target {
  display: block;
}

/* Irrelevant styles */
* {
  box-sizing: border-box;
}

/**
body {
  font-family: Lato, sans-serif;
}

a {
  text-decoration: none;
  color: #27ae60;
}

.container {
  max-width: 600px;
  margin: 20px auto;
}
**/
/**
h1 {
  font-family: Montserrat, sans-serif;
  background: #27ae60;
  color: #fff;
  margin: 0;
  padding: 60px 20px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: center;
}
**/
