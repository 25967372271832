.hero_slider.solartek_slider {
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero_slider.solartek_slider .swiper-wrapper {
    height: 100%;
}

.hero_slider.solartek_slider .swiper-slide {
    height: 100%;
    width: 100%;
    position: relative;
}

.hero_slider.solartek_slider .slide_inner {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
