/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
 }
 
 .slick-list {
    margin: 0;
    padding: 0;
    display: block;
    overflow: hidden;
    position: relative;
 }
 .slick-list:focus {
    outline: none;
 }
 .slick-list.dragging {
    cursor: hand;
    cursor: pointer;
 }
 
 .slick-slider .slick-track,
 .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 }
 
 .slick-track {
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
 }
 .slick-track:before,
 .slick-track:after {
    display: table;
 
    content: '';
 }
 .slick-track:after {
    clear: both;
 }
 .slick-loading .slick-track {
    visibility: hidden;
 }
 
 .slick-slide {
    float: left;
    height: 100%;
    display: none;
    min-height: 1px;
 }
 [dir='rtl'] .slick-slide {
    float: right;
 }
 .slick-slide img {
    display: block;
 }
 .slick-slide.slick-loading img {
    display: none;
 }
 .slick-slide.dragging img {
    pointer-events: none;
 }
 .slick-initialized .slick-slide {
    display: block;
 }
 .slick-loading .slick-slide {
    visibility: hidden;
 }
 .slick-vertical .slick-slide {
    height: auto;
    display: block;
    border: 1px solid transparent;
 }
 .slick-arrow.slick-hidden {
    display: none;
 }