/* XXl Device :1500px. */
@media (min-width: 1500px) and (max-width: 1750px) {


}
/* XL Device :1200px. */
@media (min-width: 1200px) and (max-width: 1500px) {
    .banner_number {
        margin-top: 60px;
    }
    .solartek_slider .container-fluid {
        max-width: 1140px;
    }
    .feature_inner .feature_single {
        min-height: 600px;
    }
    .testimonial_slide-wrap {
        padding: 50px;
        right: 20px;
    }
    .price_plan-wrap {
        padding-right: 150px;
    }
    .slider_content .banner_number {
        margin-top: 80px;
    }
    .main_menu ul li {
        margin-right: 15px;
    }
    .demo_dental .container {
        max-width: 1140px;
    }
    .dental_hero .hero_img {
        max-width: 600px;
    }
    .dental_hero .review_info-wrap {
        left: 0;
    }
    .header_dental .main_menu ul li {
        margin-right: 0px;
    }
    .dental_about  .about_image {
        max-width: 460px;
    }
    .dental_about .about_exp-wrap {
        right: 35px;
    }
    body.demo_dental {
        padding: 50px;
    }
    .header_dental {
        margin-top: 50px;
    }
    .d_review-wrap {
        right: 100px;
    }
    .d_contact-list li {
        margin-left: 18px;
    }
    .dl_items-carousel .slick-arrow {
        margin: 0 -50px;
    }
    .dental_hero {
        min-height: 800px;
    }
    .about_info-list:not(:last-child) {
        margin-bottom: 25px;
    }
    .header_petcare .container {
        max-width: 1460px;
    }
    .header_language {
        padding-left: 30px;
        margin-right: 20px;
    }
    .header_petcare .main_menu ul li {
        margin-right: 10px;
    }
    .petcare_hero .hero_img {
        margin-right: 0;
    }
    .pricing_single .price_list {
        padding-left: 25px;
    }
    .header_solartek {
        padding-top: 20px;
    }
    .history_wrap .history_content {
        max-width: 500px;
    }
    .page_title-petcare {
        padding-bottom: 150px;
    }
    .pricing_wrapper {
        padding-right: 50px;
    }
    [class*="col-"]:nth-child(2) .pricing_wrapper {
        padding-right: 0;
        padding-left: 50px;
    }
    [class*="col-"]:first-child .footer_widget {
        padding-right: 10px;
    }
    .demo_petcare .header_btn a:nth-child(1) {
        display: none;
    }

}
/* XL Extra */
@media (min-width: 1200px) and (max-width: 1300px) {
    .main_menu ul li {
        margin-right: 0px;
    }
    .slider_socials {
        margin-top: -90px;
    }
    .swiper-button-next, .swiper-button-prev {
        margin-top: -100px;
    }
    .price_plan-wrap {
        padding-right: 130px;
        padding-left: 40px;
    }
    body.demo_dental {
        padding: 30px;
    }
    .dental_hero .slider_content .slide_title h2 {
        font-size: 60px;
    }
    .dental_hero .hero_img {
        max-width: 550px;
    }
    .dental_hero {
        min-height: 750px;
    }
    .dental_hero .slider_content {
        margin-top: 110px;
    }
    .dental_about .about_exp-wrap {
        right: 24px;
        max-width: 190px;
    }
    .about_exp-text h4 > span {
        font-size: 50px;
    }
    .dl_items-carousel .slick-arrow {
        margin: 0 4px;
    }
    .bannler_item .banner_content {
        padding-left: 30px;
    }
    .d_review-wrap {
        right: 25px;
    }
    .d_tm-item {
        padding: 20px;
        margin-top: 45px;
    }
    .contact_form-wrap {
        padding: 50px;
    }
    .header_dental {
        margin-top: 30px;
    }

    
}


/* LG Device :992px. */
@media (min-width: 992px) and (max-width: 1199px) {
    .thm_btn {
        font-size: 14px;
        padding: 20px 30px;
    }
    .feature_inner .feature_single {
        min-height: 350px;
        width: 50%;
    }
    .feature_inner .feature_single:first-child,
    .feature_inner .feature_single:nth-child(2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    .header_icons .icon {
        display: none;
    }
    .main_menu ul li {
        margin-right: 0px;
    }
    .slider_content .slide_video {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 15px;
    }
    .slider_content .banner_number {
        margin-top: 60px;
    }
    .slider_socials {
        margin-top: -80px;
    }
    .swiper-button-next, .swiper-button-prev {
        margin-top: -95px;
    }
    .slider_content .slide_title h2 {
        font-size: 70px;
    }
    .sec_title > .st_main {
        font-size: 45px;
    }
    .experience_wrap {
        padding-left: 0;
    }
    .testimonial_slide-wrap {
        padding: 40px;
        right: 20px;
    }
    .price_plan-wrap {
        padding-left: 45px;
        padding-right: 120px;
    }
    .tm_details_carousel .tm_single {
        display: unset;
    }
    .tm_details_carousel .tm_details {
        width: 100%;
        margin-top: 20px;
    }
    .testimonial_slide-wrap .tm_details_carousel_nav {
        margin-top: 70px;
        margin-left: 120px;
    }
    .testimonial_slide-wrap .tm_details_carousel_nav button {
        left: -120px;
    }
    .testimonial_slide-wrap .tm_details_carousel_nav button.slick-next {
        left: -60px;
    }
    .header_solartek {
        padding-top: 20px;
    }
    .solartek_slider .container-fluid {
        max-width: 960px;
    }
    .dental_hero .slider_content .slide_title h2 {
        font-size: 50px;
    }
    body.demo_dental {
        padding: 20px;
    }
    .header_dental {
        margin-top: 20px;
    }
    .hero_img-wrap {
        top: 57%;
        right: 15px;
        transform: translateY(-50%);
    }
    .dental_hero .hero_img {
        max-width: 456px;
        border-radius: 50%;
        overflow: hidden;
    }
    .header_dental .header_inner {
        margin: 0 35px;
    }
    .dental_hero {
        padding: 0 15px;
        min-height: 750px;
    }
    .dental_hero .review_info-wrap {
        left: -30px;
    }
    .d_review-wrap {
        right: 15px;
    }
    .d_review-img {
        max-width: 520px;
    }
    .demo_dental .sec_title > .st_main {
        font-size: 36px;
    }
    .dental_about .about_exp-wrap {
        max-width: 180px;
        right: 20px;
    }
    .dental_about .about_image {
        max-width: 430px;
    }
    .about_exp-text h4 > span {
        font-size: 45px;
    }
    .about_info-list:not(:last-child) {
        margin-bottom: 25px;
    }
    .bannler_item .banner_content {
        padding-left: 30px;
    }
    .bannler_item .banner_content .bi_title {
        font-size: 30px;
    }
    .db_single .db_content .db_meta li {
        margin-right: 20px;
    }
    .dl_items-carousel .slick-arrow {
        display: none;
    }
    .appointmnet_inner {
        padding: 60px 40px;
    }
    .demo_dental .container {
        max-width: 960px;
    }
    .demo_petcare .header_btn a:nth-child(1), .header_language {
        display: none;
    }
    .petcare_hero .hero_img {
        margin-right: 0;
    }
    .petcare_hero .hero_content .hero_title {
        font-size: 55px;
    }
    .petcare_hero {
        min-height: 600px;
    }
    .p_about-content, .fanfact_content, .testimonial_content {
        padding-left: 50px;
    }
    .pricing_single .price_list {
        padding-left: 110px;
    }
    .demo_petcare .sec_title > .st_main {
        font-size: 42px;
    }
    .petcare_footer [class*="col-"]:nth-child(2) .footer_widget {
        margin-left: 0px;
    }
    .counter_single .counter_title {
        font-size: 60px;
    }
    .history_wrap .history_content {
        max-width: 430px;
    }
    .solartek_appointment-form {
        padding: 60px;
    }
    .details_image_wrap {
        padding-right: 140px;
    }
    .contact_form-wrap {
        padding: 50px;
    }
    .contact_info {
        display: block;
    }
    .contact_info .contact_text {
        width: 100%;
        margin-top: 20px;
    }
    .pt_icons .icon {
        max-width: 300px;
    }
    .pt_icons .icon:nth-child(2) {
        max-width: 200px;
    }
    .page_title-dental .page_title .pt_title {
        font-size: 40px;
    }
    .d_services-list li {
        min-width: 250px;
    }
    .d_services-left {
        max-width: 525px;
    }
    .P_about-right {
        padding-left: 0;
    }
    .page_title-petcare {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    .pricing_wrapper {
        padding-right: 30px;
    }
    [class*="col-"]:nth-child(2) .pricing_wrapper {
        padding-right: 0;
        padding-left: 30px;
    }
    .post_author .p_info .name {
        font-size: 28px;
    }
    .post_nav .np_title {
        font-size: 24px;
    }
}
/* MD Device :768px. */
@media (max-width: 991px) {
    .slider_socials {
        margin-top: -70px;
    }
    .slider_content .banner_number {
        margin-top: 70px;
    }
    .swiper-button-next, .swiper-button-prev {
        margin-top: -85px;
    }
    .slider_content .slide_video {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 15px;
    }
    .about_left .ep_big-number {
        font-size: 400px;
    }
    .feature_inner .feature_single {
        width: 50%;
        min-height: 350px;
    }
    .feature_inner .feature_single:first-child,
    .feature_inner .feature_single:nth-child(2) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    .sec_title > .st_main {
        font-size: 40px;
    }
    .testimonial_slide-wrap {
        position: unset;
        width: 100%;
        padding-bottom: 0;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
    }
    .price_plan-wrap {
        width: 100%;
        padding-right: 100px;
        padding-left: 100px;
    }
    .footer_top .ft_left-wrap {
        justify-content: space-between;
    }
    .footer_top .sub_from {
        padding-left: 0;
    }
    [class*="col-"]:nth-child(2) .footer_widget {
        margin-left: 0;
        padding-right: 0;
    }
    body.demo_dental {
        padding: 0;
    }
    .dental_hero .hero_img {
        max-width: 500px;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
    .hero_img-wrap {
        position: relative;
        top: 100px;
    }
    .dental_hero .review_info-wrap {
        left: 0;
    }
    .dental_hero .slider_content {
        margin-top: 130px;
    }
    .dental_hero {
        padding: 70px 0;
    }
    .header_dental {
        margin-top: 0;
    }
    .header_dental .header_inner, .header_petcare .header_inner {
        padding: 28px 0;
    }
    .header_dental .side-menu-icon i, .header_petcare .side-menu-icon i {
        color: #000;
    }
    .dental_about .about_exp-wrap {
        right: 160px;
    }
    .dl_items-carousel .slick-arrow {
        display: none;
    }
    .appointmnet_inner {
        padding: 50px 40px;
    }
    .t_image-wrap {
        margin-bottom: 30px;
    }
    .d_review-wrap, .header_language ul {
        display: none;
    }
    .demo_dental .container {
        max-width: 720px;
    }
    .d_contact-list {
        justify-content: flex-start;
    }
    .d_footer-top .sub_from {
        margin-left: auto;
        margin-right: auto;
    }
    [class*="col-"]:first-child .bannler_item {
        margin-top: 0;
    }
    .d_footer-link .footer_link li:not(:last-child) {
        margin-right: 40px;
    }
    .d_contact-list li {
        margin-left: 0;
        margin-right: 25px;
    }
    .d_contact-list li:last-child {
        margin-right: 0;
    }
    .petcare_hero .hero_content .hero_title {
        font-size: 60px;
    }
    .petcare_hero {
        padding: 80px 0;
    }
    .p_about-content, .fanfact_content, .testimonial_content {
        padding-left: 0;
    }
    .petcare_footer [class*="col-"]:nth-child(2) .footer_widget {
        margin-left: 20px;
    }
    .demo_petcare .sec_title > .st_main {
        font-size: 40px;
    }
    .header_language {
        display: none;
    }
    .page_title-area {
        padding-top: 220px;
        padding-bottom: 150px;
    }
    .page_title .pt_title {
        font-size: 60px;
    }
    .b_about-content {
        padding-left: 0;
    }
    .counter_single {
        border-left: 0;
        padding-top: 50px;
    }
    .b_counter-bg {
        padding-top: 40px;
    }
    .counter_single .counter_title {
        font-size: 60px;
    }
    .video_inner {
        margin-top: -170px;
    }
    .history_wrap .history_content {
        position: unset;
        margin-bottom: 50px;
    }
    .history_wrap::before {
        display: none;
    }
    .history_wrap .history_item {
        margin-bottom: 30px;
        padding-left: 0;
        padding-right: 0;
    }
    .history_wrap .history_left {
        margin-left: 0;
        margin-top: 0;
    }
    .history_wrap .history_right {
        margin-right: 0;
    }
    .history_wrap .history_item .number_box {
        display: none;
    }
    .history_wrap .sec_title {
        position: unset;
        margin-bottom: 50px;
    }
    .history_wrap .history_item .h_text {
        padding: 30px 25px;
    }
    .history_wrap .history_item .h_text h3 {
        font-size: 25px;
    }
    .solartek_appointment-form .form_title {
        font-size: 40px;
    }
    .solartek_appointment-form {
        padding: 50px;
    }
    .details_image_wrap {
        padding-right: 140px;
    }
    .contact_form-wrap {
        padding: 50px;
    }
    .google_map {
        height: 400px;
    }
    .google_map iframe {
        height: 400px;
    }
    .contact_info {
        display: block;
    }
    .contact_info .contact_text {
        width: 100%;
        margin-top: 20px;
    }
    .pt_icons .icon {
        max-width: 250px;
    }
    .pt_icons .icon:nth-child(2) {
        max-width: 180px;
    }
    .page_title-dental .page_title .pt_title {
        font-size: 36px;
    }
    .page_title-petcare {
        padding-top: 150px;
        padding-bottom: 170px;
    }
    .P_about-right {
        padding-left: 0;
    }
    .faq_wrapper {
        padding-left: 0;
    }
    .step_single::before {
        display: none;
    }
    .pricing_wrapper, [class*="col-"]:nth-child(2) .pricing_wrapper {
        padding: 0;
    }
    .pricing_nav li {
        margin: 0 12px;
        margin-bottom: 20px;
    }
    .petcare_footer .footer_links li {
        margin-left: 30px;
    }
    .post_author .p_info .name {
        font-size: 28px;
    }
    .post_nav .np_title {
        font-size: 24px;
    }
    .d_appointment-img {
        display: none;
    }
    .dental_hero .slider_content .slide_title h2 {
        font-size: 60px;
    }
    .d_footer-link .footer_link {
        padding-bottom: 0;
    }
}   

/* SM Device :320px. */
@media (max-width: 767px) {
    .solartek_slider .slide_title h2 {
        font-size: 40px;
    }
    .experience_wrap {
        padding-left: 0;
    }
    .about_left .ep_big-number {
        font-size: 300px;
    }
    .sec_title > .st_main {
        font-size: 36px;
    }
    .feature_inner .feature_single {
        width: 100%;
    }
    .feature_inner .feature_single:not(:last-child) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    .price_plan-wrap {
        padding-right: 15px;
        padding-left: 15px;
    }
    .swiper-button-next, .swiper-button-prev {
        display: none;
    }
    .testimonial_slide-wrap {
        padding: 70px 15px;
        padding-bottom: 0;
    }
    .price_plan-wrap {
        padding-left: 15px;
        padding-right: 15px;
    }
    .tm_details_carousel .tm_single {
        display: unset;
    }
    .tm_details_carousel .tm_details {
        width: 100%;
        margin-top: 20px;
    }
    .testimonial_slide-wrap .tm_details_carousel_nav {
        margin-top: 70px;
        margin-left: 120px;
    }
    .testimonial_slide-wrap .tm_details_carousel_nav button {
        left: -120px;
    }
    .testimonial_slide-wrap .tm_details_carousel_nav button.slick-next {
        left: -60px;
    } 
    .dental_hero .review_info-wrap {
        position: relative;
        bottom: 0;
    }
    .dental_hero .slider_content .slide_title h2 {
        font-size: 36px;
    }
    .about_exp-text h4 > span {
        font-size: 40px;
    }
    .dental_about .about_exp-wrap {
        right: 0;
    }
    .demo_dental .sec_title > .st_main {
        font-size: 32px;
    }
    .about_info-list .ai_list-text {
        width: 100%;
        margin-top: 20px;
    }
    .about_info-list:not(:last-child) {
        margin-bottom: 30px;
    }
    .bannler_item .banner_content .bi_title {
        font-size: 27px;
        margin-bottom: 20px;
    }
    .bannler_item .banner_content {
        padding-left: 20px;
    }
    .appointmnet_inner {
        padding: 30px 20px;
    }
    .d_footer-link .footer_link li:not(:last-child) {
        margin-right: 25px;
    }
    .db_single {
        display: flex;
        align-items: start;
        flex-direction: column;
    }
    .db_single .db_content {
        width: 100%;
        margin-top: 15px;
    }
    .dental_about .about_exp-wrap {
        max-width: 190px;
    }
    .petcare_hero .hero_content .hero_title, .demo_petcare .sec_title > .st_main {
        font-size: 34px;
    }
    .footer_links {
        justify-content: center;
        margin-top: 20px;
    }
    .petcare_footer .footer_bottom {
        text-align: center;
    }
    .petcare_footer [class*="col-"]:nth-child(2) .footer_widget {
        margin-left: 0;
    }
    .video_inner {
        margin-top: -105px;
    }
    .page_title .pt_title {
        font-size: 40px;
        margin-bottom: 18px;
    }
    .page_title-area {
        padding-top: 200px;
        padding-bottom: 130px;
    }
    .solartek_appointment-form {
        padding: 25px 15px;
    }
    .solartek_appointment-form .form_title {
        font-size: 35px;
    }
    .shop-filter-wrapper {
        flex-direction: column;
    }
    .pp__content {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25px !important;
    }
    .details_image_wrap .details_image_carousel_nav {
        right: 0;
    }
    .details_image_wrap {
        padding-right: 105px;
    }
    .post_item .post_content .post_title {
        font-size: 30px;
    }
    .blog__sidebar .widget {
        padding: 25px;
    }
    .post_nav .np_title {
        font-size: 22px;
    }
    .blog_post-content .blog_sub-title {
        font-size: 22px;
    }
    blockquote {
        padding: 30px 15px;
        font-size: 22px;
        line-height: 35px;
    }
    .social_share {
        margin-top: 30px;
    }
    .social_share .rt_title, .social_share ul {
        text-align: left;
    }
    .post_nav .next_post {
        text-align: left;
        padding-left: 0;
        margin-top: 30px;
    }
    .post_footer .post_nav::before {
        display: none;
    }
    .post_author {
        display: block;
        padding: 20px;
    }
    .post_author .p_image {
        margin-right: 0;
    }
    .post_author .p_info .name {
        font-size: 26px;
    }
    .post_author .p_info {
        width: calc(100%);
        margin-top: 30px;
    }
    .post_comment .comment_list li .comment_content .reply {
        position: unset;
        margin-top: 20px;
    }
    .post_comment .comment_list li .comment_author {
        position: unset;
        margin-bottom: 20px;
    }
    .post_comment .comment_list li {
        padding-left: 0;
    }
    .comment_form {
        padding: 25px;
    }
    .contact_form-wrap {
        padding: 40px 30px;
    }
    .google_map {
        height: 300px;
    }
    .google_map iframe {
        height: 300px;
    }
    .contact_form-title {
        font-size: 30px;
    }
    .pt_icons .icon {
        display: none;
    }
    .d_opening-hour {
        position: unset;
        margin-top: 30px;
        margin-bottom: -30px;
    }
    .service_content .serv_title {
        font-size: 30px;
        line-height: 44px;
    }
    .pp__item .pp__thumb {
        padding-left: 0;
    }
    .post_item-2 {
        padding: 15px;
        padding-bottom: 25px;
    }
    .post_meta li:not(:last-child) {
        margin-right: 15px;
        padding-right: 0;
    }
    .post_meta li:not(:last-child)::before {
        display: none;
    }
    .gallery_post-active .slick-arrow {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
    .page_title-petcare .page_title .pt_title {
        font-size: 35px;
    }
    .page_title-petcare {
        padding-top: 140px;
        padding-bottom: 130px;
    }
    .p_video-left {
        margin-right: 0;
    }
    .video_icon {
        height: 80px;
        width: 80px;
        line-height: 80px;
    }
    .pricing_nav li .nav-link {
        padding: 12px 35px;
    }
    .pricing_nav li {
        margin: 0 7px;
        margin-bottom: 20px;
    }
    .price_plan-list li {
        font-size: 18px;
    }
    .team_single-2 .team_info .team_title {
        font-size: 18px;
    }
    .post_item .post_content .post_title {
        font-size: 30px;
        line-height: 42px;
    }
    .post_author .p_info .name {
        font-size: 24px;
    }
    .d_appointment-form {
        padding: 50px 25px;
    }
}
/* SM Extra */
@media (max-width: 380px) {
    .footer_top .sub_from button {
        position: unset;
        margin-top: 15px;
        width: 100%;
    }
    .fanfact_content .fanfact_item {
        display: inherit;
    }
    .fanfact_item .fanfact_desc {
        width: 100%;
        padding-left: 0;
        border-left: 0;
        margin-top: 15px;
    }


}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
    
}